import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import {
    Wrapper,
    NavWrapper,
    Header,
    Headline,
    CopyText,
    GatsbyImg,
} from "../styles/components/products"
import styled from "styled-components"

import {
    Swiper, SwiperSlide
} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

const SuperWrapper = styled.div`
  margin: 20px 17px 0 17px;
  @media (min-width: 1280px) {
    margin: 60px 26px 0 26px;
  }
`

const ArticleNavigation = styled.div`
    display: none;
    @media(min-width: 1280px) {
    display: block;
    flex: 20%;
    height: 100%;
  }
`

const ArticleContainer = styled.div`
    flex: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`

const ArticleWrapper = styled(motion.article)`
    flex: 100%; 
    border: 1px solid #F1F2F2;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    @media(min-width: 1280px) {
        flex: 45%;
        max-width: 50%;
    }
`

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;

`

const ImgContainer = styled.div`
    /* height: 50%; */#
 
`

const ArticleFlexContainer = styled.div`
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

const FlexItem1 = styled.div`

    /* height: 500px; */
`

const FlexItem2 = styled.div`
    /* flex: 1; */
    position: absolute;
    top: 100%;
`

const InnerFlexItem = styled.div`
    flex: 1;
    display: flex;
    align-items: ${props => props.flex};
    flex-direction: column;
`

const InnerH2 = styled.h2`
    font-family: Courier New,Courier,monospace;
    font-weight: 600;
    font-size: 1.25rem;
`

const DetailsButtonWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
`

const DetailsButton = styled.button`
    cursor: pointer;
    border: none;
    border-radius: 100vw;
    background: #B9789F;
    padding: 1rem 2rem;
    transition: 1s;
    color: white;
`

const Text = styled.p`
    padding: 1rem 2rem;
    letter-spacing: .75px;
    line-height: 1.75rem;
`

const variants = {
    closed: {
        y: 0,
        transition: {
            // delay: 1,
            duration: .75
        }
    },
    open: {
        y: "-75%",
        transition: {
            // delay: 1,
            duration: .75
        }
    }
}

const imgStyle = {
    maxHeight: "500px"     
}

const SaleSwiper = ({ img }) => {
    return (
        <Swiper
            navigation={{
                clickable: true,
            }}
            pagination={{
                clickable: true,
            }}
            spaceBetween={0}
            slidesPerView={1}
            style={{
                maxWidth: "100%",
                width: "600px",
            }}
        >
            {img.map(img =>
                <SwiperSlide 
                style={{
                    // width: "500px"
                }}
                >
                    <GatsbyImg 
                    // imgStyle={{
            
                    // }} 
             
                    fluid={img} />
                </SwiperSlide>
            )}
        </Swiper>
    )
}

const SaleArticle = ({ index, title, preis, salepreis, img, desc }) => {
    const [open, setOpen] = useState(false);
    return (
        <ArticleWrapper key={index}>
            <Container variants={variants} initial="closed" animate={open ? "open" : "closed"}>

                <FlexItem1>
                    <ImgContainer>
                        <SaleSwiper img={img} />
                    </ImgContainer>
                    <ArticleFlexContainer>
                        <InnerFlexItem><InnerH2>{title}</InnerH2></InnerFlexItem>
                    <InnerFlexItem
                    flex="flex-end"
                    >
                        {`Vorher: ${preis}€`} <br />
                        <strong>{`Jetzt: ${salepreis}€`} </strong>
                        </InnerFlexItem>
                        </ArticleFlexContainer>
                    <DetailsButtonWrapper>
                        <DetailsButton onClick={() => setOpen(!open)}>{open ? "Produktdetails schließen" : "Produktdetails"}</DetailsButton>
                    </DetailsButtonWrapper>
                </FlexItem1>

                <FlexItem2>
                    <Text>{desc}</Text>
                </FlexItem2>

            </Container>
        </ArticleWrapper>
    )
}

const Sale = ({ data, props }) => {
    const content = data.allWpPage.nodes[0]
    // console.log(content.pageTypeNewSale.produkt.map(ele => ele.gallerie.map((img => img.localFile.childImageSharp.fluid))))
    return (
        <Layout>
            <SEO title={content.title} />
<SuperWrapper>

            <Header>
                <Headline>
                    {`Unser ${content.title}`}
                </Headline>
                <CopyText>
                    {content.pageTypeNewSale.textbeitrag}
                </CopyText>
            </Header>

            <Wrapper>

                <ArticleNavigation>
                    <NavWrapper>
                        <ul>
                            <li>
                                <Link></Link>
                            </li>
                            <li>
                                <Link></Link>
                            </li>
                            <li>
                                <Link></Link>
                            </li>
                        </ul>
                    </NavWrapper>
                </ArticleNavigation>

                <ArticleContainer>
                    {content.pageTypeNewSale.produkt.map((ele, index) => (
                        <SaleArticle
                            index={ele.index}
                            img={ele.gallerie.map((img => img.localFile.childImageSharp.fluid))}
                            title={ele.title}
                            preis={ele.preis}
                            salepreis={ele.salepreis}
                            desc={ele.beschreibung}
                        />
                    ))}
                </ArticleContainer>

            </Wrapper>
            </SuperWrapper>
        </Layout>
    )
}

export default Sale



export const query = graphql`
query {
    allWpPage(filter: {title: {eq: "Sale"}}) {
      nodes {
        pageTypeNewSale {
          textbeitrag
          produkt {
            gallerie {
              localFile {
                childImageSharp {
                    fluid( maxWidth: 800 maxHeight: 800 quality: 100 cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
                }
              }
            }
            title
            preis
            salepreis
            beschreibung
          }
        }
        title
      }
    }
  }
`



